.nav-toggle {
	width: 1.5rem;
	height: 1.5rem;
	background-image: url('/assets/img/icon-menu-open.png');
	background-position: 50%;
	background-size: contain;
	background-repeat: no-repeat;
	background-color: transparent;
	border: 0;
	appearance: none;
	cursor: pointer;
	z-index: 15;

	&--active {
		background-image: url('/assets/img/icon-menu-close.png');
	}
}

.nav {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 10;
	background-color: rgba(255,255,255,0);
	backdrop-filter: unset;
	pointer-events: none;
	transition: background-color 0.1s;

	&--active {
		pointer-events: all;
		background-color: rgba(255,255,255,0.7);
		backdrop-filter: blur(8px);
		
		.nav__list {
			transform: translateY(0);
			opacity: 1;
		}
	}

	&__list {
		list-style: none;
		padding: 10vh 0 0;
		width: 100%;
		text-align: center;
		transform: translateY(10%);
		opacity: 0;
		transition: transform 0.2s, opacity 0.1s;
	}

	&__item {
		@include font-preset--3;
		margin: 1ex 0 0;
	}

	&__link {
		text-decoration: none;
		color: inherit;

		&:hover {}
	}
}

