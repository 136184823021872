.form {
	input,
	textarea {
		width: 100%;
		margin-top: 0.7rem;
		padding: 0.3rem;
	}

	label {}
}

