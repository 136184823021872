.intro {
	&__container {}

	&__columns {
		display: flex;
		flex-wrap: wrap;
		padding: 1.75rem 0;
	}

	&__col {
		width: 100%;
		padding: 0 var(--inner-spacing) 1.25rem;

		@media (min-width: 850px) {
			width: 50%;
		}
	}

	&__background {
		position: absolute;
		top: 0;
		left: 0;
		height: 100%;
		width: 100%;

		&:not(&.color--one, &.color--five) {
			~ * {
				color: #fff;
			}
		}
	}

	&__heading {
		width: 100%;
		margin-top: 0;
		padding: 0 var(--inner-spacing) 1.25rem;
	}

	&__intro {
		@include font-preset--7;
	}

	p {
		margin: 0;
	}

	p + p {
		margin: 2.6ex 0 0;
	}
}
