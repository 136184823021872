:root {
	// COLOURS
	--color-one: #232323; // Black
	--color-two: #fff; // White
	--color-three: #e4e4e4; // GREY

	
	// COLOURS USED IN THE PANEL COLOUR SELECTORS
	--color-four: var(--color-three);
	--color-five: var(--color-one);
	--color-six: #234592;
	--color-seven: #075AC2;
	--color-eight: var(--color-two);
	--color-nine: #DD5700;
	--color-ten: #F17B0D;
	--color-eleven: #D00086;
	--color-twelve: #D836A5;
	

	
	// TYPOGRAPHY
	--font-one: 'Open Sans', sans-serif;
	
	// CONTAINER SPACING
	--container-spacing: 1.5rem;
	@media (min-width: 1200px) {
		--container-spacing: 5rem;
	}

	// INNER SPACING
	--inner-spacing: 1.5rem;
	@media (min-width: 900px) {
		--inner-spacing: 3.5rem;
	}


}


