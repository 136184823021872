.panels {

	h2 {
		max-width: unset;
	}

	&--single {
		.panels__item {
			width: 100%;
			text-align: center;
		}
	}

	&__container {}

	&__columns {
		display: flex;
		flex-wrap: wrap;
	}

	&__item {
		width: 100%;
		padding: var(--inner-spacing);

		a {
			text-decoration: none;
		}

		@media (min-width: 770px) {
			width: 50%;
		}

		&:not(&.color--one, &.color--five) {
			color: #fff;

			img {
				filter: invert(1);
			}
		}
	}

	&__heading {
		@include font-preset--4;
		margin-top: 0;

		img {
			display: inline;
			width: 0.85ex;
			margin-left: 0.8ex;
		}
	}

	&__copy {
		margin-top: 1.6ex;
		max-width: 400px;
	}
}

