.bold,
p strong {
	font-weight: 800;
}

.italic,
p em {
	font-style: italic;
}

.underline {
	text-decoration: underline;
}

.upper {
	text-transform: uppercase;
}

.hidden {
	display: none;
}

.image-preload {
	height: 0;
	overflow: hidden;
}

.color {
	&--one {
		background-color: var(--color-four);
	}

	&--two {
		background-color: var(--color-five);
	}

	&--three {
		background-color: var(--color-six);
	}

	&--four {
		background-color: var(--color-seven);
	}

	&--five {
		background-color: var(--color-eight);
	}
	
	&--six {
		background-color: var(--color-nine);
	}
	
	&--seven {
		background-color: var(--color-ten);
	}
	
	&--eight {
		background-color: var(--color-eleven);
	}
	
	&--nine {
		background-color: var(--color-twelve);
	}
}
