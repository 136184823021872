.container {
	// max-width: 102rem;
	margin: auto;
	// padding-left: var(--container-spacing);
	// padding-right: var(--container-spacing);

	&--narrow {
		max-width: 700px;
	}
}


.columns {
	list-style: none;
	padding: 0;
	margin: 0 -1.25rem;
	display: flex;
	flex-wrap: wrap;

	> * {
		width: calc(100% - 2.5rem);
		margin: 0 1.25rem 2.5rem;
	}

	&--2 {
		> * {
			width: calc(100% - 2.5rem);
			margin: 0 1.25rem 2.5rem;

			@media (min-width: 850px) {
				width: calc(50% - 2.5rem);
			}
		}
	}

	&--4 {
		> * {
			width: calc(25% - 2.5rem);
			margin: 0 1.25rem 2.5rem;
		}
	}
}