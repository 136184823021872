.contactForm {
	padding: var(--inner-spacing);

	&__row {
		margin-top: 2rem;
	}

	&__heading {
		@include font-preset--6;
		text-transform: uppercase;
		margin-top: 0;
	}

	&__button {}
}