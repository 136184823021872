.listing {
	margin: 4rem auto 0;

	+ * {
		margin-top: 4rem;
	}

	+ .listing {
		margin-top: 1rem;
	}

	.columns {
		padding: 0 var(--inner-spacing);
	}

	&__card {
		@media (max-width: 850px) {
			max-width: 560px;
		}
	}

	&__heading {
		@include font-preset--3;
		margin-top: 0;
		margin-bottom: 1.5rem;
	}

	&__link {
		display: flex;
		justify-content: space-between;
		align-items: center;
		text-decoration: none;
		color: inherit;
	}

	&__image {
		width: 6rem;
		height: 6rem;
		background-size: cover;
		background-position: 50%;
		border-radius: 100%;

		@media (min-width: 550px) {
			width: 10rem;
			height: 10rem;
		}

		@media (min-width: 1000px) {
			width: 15rem;
			height: 15rem;
		}

		&--blank {
			background-color: var(--color-three);
		}
	}

	&__text {
		width: calc(100% - 7rem);

		@media (min-width: 550px) {
			width: calc(100% - 12rem);
		}

		@media (min-width: 1000px) {
			width: calc(100% - 17rem);
		}
	}

	&__sub-heading {
		@include font-preset--5;
		margin-top: 0;
	}

	&__more {
		text-align: right;
		display: block;
		margin-top: -0.5rem;
		color: inherit;
		text-decoration: none;
	}
}

