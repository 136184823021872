*,
*::before,
*::after {
	box-sizing: border-box;
	position: relative;
}

html {
	background-color: var(--color-two);
	color: var(--color-one);
	font-size: 16px;
	font-family: var(--font-one);
	line-height: 1.3;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	font-feature-settings: 'tnum';
	font-variant-numeric: tabular-nums;
	text-rendering: optimizeSpeed;
	font-variant-ligatures: common-ligatures;
	overflow-x: hidden;
	padding: 0;
	scroll-behavior: smooth;
}

main {
	> * {
		&:not(:first-of-type) {
			content-visibility: auto;
		}
	}
}

.skip-main {
	left: 50%;
	position: absolute;
	top: 0;
	z-index: 2;
	transform: translate(-50%, -100%);
	transition: transform 0.2s;

	&:focus {
		transform: translate(-50%, 0);
	}
}

body,
figure,
figcaption,
blockquote,
dl,
dd {
	margin: 0;
}

img,
picture,
svg,
video,
hr {
	width: 100%;
	display: block;
}

table,
th,
td {
	border: 1px solid currentColor;
	text-align: left;
}

td,
th {
	padding: 1rem;
	vertical-align: top;
}

table {
	border-collapse: collapse;
	margin: 2rem 0;
}

th {
	font-weight: 800;
}


::selection {
	background: var(--color-one);
	color: var(--color-two);
}

*:focus {
	outline: 1px solid var(--color-one);
	outline-offset: 4px;
}
