.header {
	&__container {
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding-top: 1.875rem;
		padding-bottom: 1.875rem;
		padding-left: var(--inner-spacing);
		padding-right: var(--inner-spacing);
	}

	&__logo {
		width: 8rem;
		margin-right: 3rem;

		@media (min-width: 500px) {
			width: 9rem;
		}

		@media (min-width: 700px) {
			width: 12rem;
		}
	}

	&__nav {
		width: calc(100% - 16.3rem);
	}
}
