
$footer-banner-break-one: 800px;

.footer-banner {
	&__container {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
	}

	&__logos {
		display: flex;
		flex-direction: column;
		justify-content: center;
		width: 100%;
		padding: 4rem var(--inner-spacing);
		background-color: var(--color-five);

		@media (min-width: $footer-banner-break-one) {
			width: 60%;
		}
	}

	&__logos-header {
		@include font-preset--6;
		margin: 0 0 1ex;
		color: var(--color-two);
	}

	&__logos-list {
		list-style: none;
		display: flex;
		align-items: center;
		padding: 0;
	}

	&__logos-item {
		margin: 0;

		&:not(:last-of-type) {
			margin: 0 1rem 0 0;
		}
	}

	&__contact {
		display: flex;
		flex-direction: column;
		justify-content: center;
		width: 100%;
		padding: 2rem var(--inner-spacing);
		background-color: var(--color-seven);
		color: var(--color-two);
		text-align: center;

		@media (min-width: $footer-banner-break-one) {
			width: 40%;
			text-align: left;
		}
	}

	&__contact-header {
		@include font-preset--4;
		margin-top: 0;
	}

	&__contact-text {
		margin-top: 1.6ex;
	}

	&__button {
		margin-top: 1.6ex;
	}
}

.footer {
	margin-top: 0;

	&__container {}
	
	&__content {
		background-color: var(--color-two);
		padding: 2rem var(--inner-spacing);
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;
	}

	&__copyright {
		margin: 0;
	}

	&__nav {}

	&__nav-list {
		list-style: none;
		margin: 0;
		padding: 0;
	}

	&__nav-item {
		margin: 0;
	}

	&__nav-link {
		color: inherit;
		text-decoration: none;
	}
}


