.hero {
	background-size: cover;
	background-position: 50%;
	min-height: 65vh;
	padding: 5rem 0;

	&__container {}

	&__content {
		max-width: 550px;
		padding: var(--inner-spacing);
	}

	&__heading {
		margin-top: 0;
	}

	&__copy {}

	&__button {
		margin-top: 2ex;
	}
}

