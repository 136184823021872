$alternating-break-one: 850px;

.alternating {
	margin-bottom: -1px;

	+.alternating {
		.alternating__columns {
			// margin-top: -3.5rem;
		}
	}

	&--align-top {
		.alternating__columns {
			align-items: flex-start;
		}
	}


	@media (min-width: $alternating-break-one) {
		&--flip {
			.alternating__columns {
				flex-direction: row-reverse;
			}

			.alternating__image {
				padding: 0 var(--inner-spacing) 1.25rem 0;
			}
		}
	}

	&__container {}

	&__columns {
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		padding: 3.25rem 0;
	}

	&__image,
	&__text {
		width: 100%;
		padding: 0 var(--inner-spacing) 1.25rem;

		@media (min-width: $alternating-break-one) {
			width: 50%;
		}
	}

	&__image {
		border-radius: 2rem;

		@media (min-width: $alternating-break-one) {
			padding: 0 0 1.25rem var(--inner-spacing);
		}
	}

	picture {
		border-radius: 2rem;
		overflow: hidden;
	}
	
	&__background {
		position: absolute;
		top: 0;
		left: 0;
		height: 100%;
		width: 100%;

		&:not(&.color--one, &.color--five) {
			~ .alternating__text {
				color: #fff;
			}
		}
	}

	&__heading {
		@include font-preset--5;
		margin-top: 0;
	}

	&__copy {
		p {
			a {
				text-decoration: underline;
			}
		}
	}
}


