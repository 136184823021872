.button {
	font-weight: 400;
	display: inline-block;
	text-decoration: none;
	text-align: center;
	padding: 0.7rem 1.5rem 0.85rem;
	cursor: pointer;
	border: 0;
	background-color: var(--color-one);
	color: var(--color-two);
	border-radius: 10rem;
	border: 2px solid var(--color-one);
	transition: background-color 0.2s;

	&:hover {
		background-color: var(--color-two);
		color: var(--color-one);
	}

	&--reverse {
		background-color: var(--color-two);
		color: var(--color-one);
		border: 2px solid var(--color-two);

		&:hover {
			background-color: var(--color-one);
			color: var(--color-two);
		}
	}
}


