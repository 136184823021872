$--font-break-one: 1000px;
$--font-break-two: 600px;

// HEADINGS
@mixin font-preset--1 {
	font-size: 2rem;
	font-weight: 800;
	margin-bottom: -0.7ex;
	line-height: 1.2;
	text-transform: uppercase;

	@media (min-width: $--font-break-two) {
		font-size: 2.5rem;
	}

	@media (min-width: $--font-break-one) {
		font-size: 3.25rem;
	}
}

@mixin font-preset--2 {
	font-size: 1.7rem;
	font-weight: 800;
	margin-bottom: -0.5ex;
	line-height: 1.2;
	text-transform: uppercase;
	
	@media (min-width: $--font-break-two) {
		font-size: 2.2rem;
	}

	@media (min-width: $--font-break-one) {
		font-size: 3rem;
	}
}

@mixin font-preset--3 {
	font-size: 1.45rem;
	font-weight: 800;
	margin-bottom: -0.5ex;

	@media (min-width: $--font-break-two) {
		font-size: 2rem;
	}

	@media (min-width: $--font-break-one) {
		font-size: 2.6rem;
	}
}

@mixin font-preset--4 {
	font-size: 1.2rem;
	font-weight: 800;

	@media (min-width: $--font-break-two) {
		font-size: 1.6rem;
	}

	@media (min-width: $--font-break-one) {
		font-size: 2rem;
	}
}

@mixin font-preset--5 {
	font-size: 1.15rem;
	font-weight: 800;

	@media (min-width: $--font-break-two) {
		font-size: 1.5rem;
	}
}

@mixin font-preset--6 {
	font-size: 1rem;
	font-weight: 800;

	@media (min-width: $--font-break-two) {
		font-size: 1.25rem;
	}
}

@mixin font-preset--7 {
	font-size: 1rem;
	font-weight: 400;

	@media (min-width: $--font-break-two) {
		font-size: 1.12rem;
	}
}

@mixin font-preset--8 {
	font-size: 1rem;
	font-weight: 400;
}


h1,
h2,
h3,
h4,
h5,
h6,
li,
p {
	@include font-preset--8;

	margin: 2.6ex 0 0;
	line-height: 1.5;
	letter-spacing: 0.05ex;
}

p {
	max-width: 100ch;
}

a:not([class]) {
	color: currentColor;
	text-decoration: underline;
	text-decoration-thickness: 1px;
	text-decoration-color: var(--color-one);
	transition: color 0.2s ease;
	text-decoration-skip-ink: auto;

	// &:hover {
	// 	color: var(--color-one);
	// }
}

ul,
ol {
	list-style: disc;
	padding-left: 1.8ex;
}

li {
	margin: 2.6ex 0 0;

	+ li {
		margin: 1.6ex 0 0;
	}
}

.font-preset--1,
h1 {
	@include font-preset--1;
}

.font-preset--2,
h2 {
	@include font-preset--2;
}

.font-preset--3,
h3 {
	@include font-preset--3;
}

.font-preset--4,
h4 {
	@include font-preset--4;
}

.font-preset--5,
h5 {
	@include font-preset--5;
}

